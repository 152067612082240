import { computed } from "vue";
import { simplifyTailwindClasses } from "@/utils/simplifyTailwindClasses";

// LABEL
const defaultLabelClasses = "block text-sm font-medium text-send-grey";

// CHECKBOX
const defaultCheckboxClasses = "w-4 h-4 rounded bg-gray-100 border-gray-300";

export function useCheckboxClasses() {
  const checkboxClasses = computed(() =>
    simplifyTailwindClasses(defaultCheckboxClasses)
  );
  const labelClasses = computed(() => defaultLabelClasses);

  return {
    checkboxClasses,
    labelClasses,
  };
}
