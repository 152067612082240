<template>
  <label class="flex gap-3 items-center justify-start cursor-pointer">
    <div
      class="checkbox-wrapper"
      :class="{
        'checkbox-wrapper--checked': model,
        'checkbox-wrapper--disabled': disabled,
      }"
    >
      <input
        v-model="model"
        :class="checkboxClasses"
        :disabled="disabled"
        type="checkbox"
      />
    </div>
    <span v-if="label" :class="labelClasses">
      {{ label }}
      <slot name="link" />
    </span>
    <slot />
  </label>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { useCheckboxClasses } from "./composables/useCheckboxClasses";

interface CheckboxProps {
  disabled?: boolean;
  label?: string;
  modelValue?: boolean | Ref<boolean>;
}
const props = withDefaults(defineProps<CheckboxProps>(), {
  disabled: false,
  label: "",
  modelValue: false,
});

const emit = defineEmits(["update:modelValue"]);

const model = computed({
  get() {
    return props.modelValue;
  },
  set(val) {
    emit("update:modelValue", val);
  },
});

const { checkboxClasses, labelClasses } = useCheckboxClasses();
</script>

<style lang="scss" scoped>
.checkbox-wrapper {
  input {
    display: none;
  }

  &:after {
    content: "";
    @apply border-2 rounded border-send-grey-100 w-4 h-4 block visible relative bg-[size:95%] bg-center bg-no-repeat bg-[url('/assets/images/check.svg')];
  }

  &--checked {
    &:after {
      @apply bg-send-grey-100;
    }
  }

  &--disabled {
    &:after {
      @apply border bg-send-grey-80 border-send-grey-80;
    }
  }
}
</style>
